<template>
  <common-trs-page-container>
    <template v-slot:page-content>
      <common-app-page-header
        :title="'Equity Incentive Plan'"
      />
      <common-page-loader v-if="loading" />
      <div class="trs-form-container-card">
        <common-trs-card class="pa-2 pb-0 mb-2">
          <validation-observer
            ref="observer"
            v-slot="{ valid }"
            class="ml-auto"
          >
            <v-card-text class="px-0">
              <eip-form
                v-model="eipData"
                :eip-id="id"
                :mode="'edit'"
                :internal-dialog="internalDialog"
                :is-submit="isSubmit"
                :share-terms="shareTermList"
                @navToList="navToList"
              />
            </v-card-text>
            <v-row class="mx-3">
              <v-col>
                <v-divider />
                <div class="d-flex justify-space-between align-center pt-3">
                  <common-trs-btn
                    type="secondary"
                    medium
                    class="pull-right mr-2"
                    text
                    :to="{name: 'EquityIncentivePlans'}"
                  >
                    Cancel
                  </common-trs-btn>
                  <common-trs-btn
                    type="primary"
                    medium
                    class="white--text pull-right"
                    text
                    :disabled="!valid"
                    :loading="loading"
                    @click="save"
                  >
                    Save
                  </common-trs-btn>
                </div>
              </v-col>
            </v-row>
          </validation-observer>
        </common-trs-card>
      </div>
    </template>
  </common-trs-page-container>
</template>

<script>
  import * as captableService from '@/components/common/captable/services/captable'
  import EipForm from '@/components/common/captable/components/forms/EipForm'
  export default {
    name: 'EditFinancingRound',
    components: {
      EipForm,
    },
    data: () => ({
      loading: false,
      eipData: {},
      shareTermList: [],
      isSubmit: false,
      internalDialog: false,
    }),

    computed: {
      captableId () {
        return this.$store.getters['auth/capTableId']
      },
      id () {
        return this.$route.query.id
      },
    },
    watch: {
      internalDialog: {
        handler (val) {
          if (val) {
            this.isSubmit = false
          }
        },
      },
    },
    mounted () {
      this.getShareTermList()
      this.loadData()
    },
    methods: {
      async getShareTermList () {
        try {
          this.loading = true
          const resp = await captableService.getShareTermList(this.captableId)
          if (resp && resp.data) {
            this.shareTermList = resp.data.share_terms
          }
          this.loading = false
        } catch (e) {
          this.loading = false
        }
      },

      async loadData () {
        this.loading = true
        try {
          const resp = await captableService.getEipById(this.captableId, this.id)
          this.eipData = resp.data
          this.internalDialog = true
          this.loading = false
        } catch (err) {
          this.loading = false
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Sorry! Unable to save financing round. Please try again later.',
          })
        }
      },

      async save () {
        this.clearNullData()
        this.loading = true
        try {
          const resp = await captableService.updateEipById(this.captableId, this.id, this.eipData)
          if (resp.status === 204) {
            this.isSubmit = true
          }
        } catch (err) {
          this.loading = false
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Sorry! Unable to save financing round. Please try again later.',
          })
        }
      },

      navToList () {
        this.loading = false
        this.$store.dispatch('app/message', { text: 'Financing round saved successfully.' })
        this.$router.push({ name: 'EquityIncentivePlans' })
      },

      clearNullData () {
        Object.keys(this.eipData).forEach((key) => {
          if (this.eipData[key] === null || this.eipData[key] === undefined) {
            delete this.eipData[key]
          }
        })
      },
    },
  }
</script>
