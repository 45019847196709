var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"form"},[_c('v-row',{staticClass:"ma-0 mt-4"},[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":'Share term',"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-select',{attrs:{"label":"Share Term","placeholder":"Select share term","items":_vm.shareTerms,"item-text":"name","item-value":"id","error-messages":errors,"hide-details":errors.length === 0},on:{"change":_vm.updateValue},model:{value:(_vm.value.share_terms_id),callback:function ($$v) {_vm.$set(_vm.value, "share_terms_id", $$v)},expression:"value.share_terms_id"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","lg":"6","md":"6"}},[_c('validation-provider',{attrs:{"name":'Plan name',"rules":{ required: true, max: 255 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-text-field',{attrs:{"label":"Plan Name","color":"primary","error-messages":errors,"hide-details":errors.length === 0},on:{"input":_vm.updateValue},model:{value:(_vm.value.name),callback:function ($$v) {_vm.$set(_vm.value, "name", $$v)},expression:"value.name"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","lg":"6","md":"6"}},[_c('validation-provider',{attrs:{"name":'Prefix',"rules":{ required: true, max: 20 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-text-field',{attrs:{"label":"Prefix","color":"primary","error-messages":errors,"hide-details":errors.length === 0},on:{"input":_vm.updateValue},model:{value:(_vm.value.prefix),callback:function ($$v) {_vm.$set(_vm.value, "prefix", $$v)},expression:"value.prefix"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","lg":"6","md":"6"}},[_c('validation-provider',{attrs:{"name":'Holder approval date',"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-template-date',{attrs:{"mask":'##/##/####',"error-messages":errors,"hide-details":errors.length === 0,"input-templ":{label: 'Holder approval date', mandatory: true},"clearable":""},on:{"change":_vm.updateValue},model:{value:(_vm.holderApprovalDate),callback:function ($$v) {_vm.holderApprovalDate=$$v},expression:"holderApprovalDate"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","lg":"6","md":"6"}},[_c('validation-provider',{attrs:{"name":'Board approval date',"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-template-date',{attrs:{"mask":'##/##/####',"error-messages":errors,"hide-details":errors.length === 0,"input-templ":{label: 'Board approval date', mandatory: true},"clearable":""},on:{"change":_vm.updateValue},model:{value:(_vm.boardApprovalDate),callback:function ($$v) {_vm.boardApprovalDate=$$v},expression:"boardApprovalDate"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","lg":"6","md":"6"}},[_c('validation-provider',{attrs:{"name":'Plan end date'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-template-date',{attrs:{"mask":'##/##/####',"error-messages":errors,"hide-details":errors.length === 0,"input-templ":{label: 'Plan end date'},"clearable":""},on:{"change":_vm.updateValue},model:{value:(_vm.planEndDate),callback:function ($$v) {_vm.planEndDate=$$v},expression:"planEndDate"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","lg":"6","md":"6"}},[_c('validation-provider',{attrs:{"name":'Authorized shares',"rules":{ required: true, min: 0 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-currency-input',{attrs:{"label":"Authorized shares","color":"primary","error-messages":errors,"hide-details":errors.length === 0,"options":{ precision: 0 }},on:{"input":_vm.updateValue},model:{value:(_vm.value.authorized_shares),callback:function ($$v) {_vm.$set(_vm.value, "authorized_shares", $$v)},expression:"value.authorized_shares"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":'Return forfeited'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-checkbox',{attrs:{"label":"Return forfeited options back to pool","color":"primary","error-messages":errors},model:{value:(_vm.value.return_forfeited),callback:function ($$v) {_vm.$set(_vm.value, "return_forfeited", $$v)},expression:"value.return_forfeited"}})]}}])})],1)],1),_c('v-row',{staticClass:"ma-0"},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"trsText--text text-h4 font-weight-medium"},[_vm._v(" Termination grace periods ")]),_c('div',{staticClass:"trsText--text text-caption text--light-1 mt-2"},[_vm._v(" You can add more than one grace period by selecting the type of grace period in the drop down below ")])]),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":'Grace Period'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-select',{attrs:{"label":"Grace Period (optional)","placeholder":"Select grace period","items":_vm.gracePeriodList,"item-text":"title","item-value":"title","error-messages":errors,"hide-details":errors.length === 0},on:{"change":_vm.addGracePeriod},model:{value:(_vm.gracePeriod),callback:function ($$v) {_vm.gracePeriod=$$v},expression:"gracePeriod"}})]}}])})],1)],1),_vm._l((_vm.selectedGracePeriod),function(item,key){return _c('v-row',{key:key,staticClass:"ma-0 mt-2 grace-period-row"},[_c('v-col',[_vm._v(" "+_vm._s(item.name)+" ")]),_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"name":'Duration',"rules":{ required: true, min_value: 0 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-text-field',{attrs:{"label":"Duration","color":"primary","type":"number","error-messages":errors,"hide-details":errors.length === 0},on:{"input":_vm.updateGracePeriod},model:{value:(item.duration),callback:function ($$v) {_vm.$set(item, "duration", $$v)},expression:"item.duration"}})]}}],null,true)})],1),_c('v-col',[_c('validation-provider',{attrs:{"name":'Duration type',"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-select',{attrs:{"label":"Duration Type","placeholder":"Select duration type","items":_vm.CONSTS.GRACE_PERIOD_DURATION_TYPE_LIST,"item-text":"title","item-value":"value","error-messages":errors,"hide-details":errors.length === 0},on:{"change":_vm.updateGracePeriod},model:{value:(item.duration_type),callback:function ($$v) {_vm.$set(item, "duration_type", $$v)},expression:"item.duration_type"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-icon',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.deleteGracePeriod(key)}}},[_vm._v(" mdi-delete ")])],1)],1)}),_c('v-row',{staticClass:"mx-0"},[_c('v-col',{attrs:{"cols":"12"}},[_c('notes-and-documents-form',{attrs:{"id":_vm.eipId,"mode":_vm.mode,"is-submit":_vm.isSubmit,"internal-dialog":_vm.internalDialog,"type":_vm.CONSTS.NOTES_DOCUMENT_API_TYPE.EQUITY_INCENTIVE_PLANS},on:{"navToList":function($event){return _vm.$emit('navToList', true)}}})],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }