<template>
  <v-form ref="form">
    <v-row class="ma-0 mt-4">
      <v-col
        cols="6"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="'Share term'"
          :rules="{ required: true }"
        >
          <common-input-select
            v-model="value.share_terms_id"
            label="Share Term"
            placeholder="Select share term"
            :items="shareTerms"
            item-text="name"
            item-value="id"
            :error-messages="errors"
            :hide-details="errors.length === 0"
            @change="updateValue"
          />
        </validation-provider>
      </v-col>
      <v-col
        cols="12"
        lg="6"
        md="6"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="'Plan name'"
          :rules="{ required: true, max: 255 }"
        >
          <common-input-text-field
            v-model="value.name"
            label="Plan Name"
            color="primary"
            :error-messages="errors"
            :hide-details="errors.length === 0"
            @input="updateValue"
          />
        </validation-provider>
      </v-col>
      <v-col
        cols="12"
        lg="6"
        md="6"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="'Prefix'"
          :rules="{ required: true, max: 20 }"
        >
          <common-input-text-field
            v-model="value.prefix"
            label="Prefix"
            color="primary"
            :error-messages="errors"
            :hide-details="errors.length === 0"
            @input="updateValue"
          />
        </validation-provider>
      </v-col>
      <v-col
        cols="12"
        lg="6"
        md="6"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="'Holder approval date'"
          :rules="{ required: true }"
        >
          <input-template-date
            v-model="holderApprovalDate"
            :mask="'##/##/####'"
            :error-messages="errors"
            :hide-details="errors.length === 0"
            :input-templ="{label: 'Holder approval date', mandatory: true}"
            clearable
            @change="updateValue"
          />
        </validation-provider>
      </v-col>
      <v-col
        cols="12"
        lg="6"
        md="6"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="'Board approval date'"
          :rules="{ required: true }"
        >
          <input-template-date
            v-model="boardApprovalDate"
            :mask="'##/##/####'"
            :error-messages="errors"
            :hide-details="errors.length === 0"
            :input-templ="{label: 'Board approval date', mandatory: true}"
            clearable
            @change="updateValue"
          />
        </validation-provider>
      </v-col>
      <v-col
        cols="12"
        lg="6"
        md="6"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="'Plan end date'"
        >
          <input-template-date
            v-model="planEndDate"
            :mask="'##/##/####'"
            :error-messages="errors"
            :hide-details="errors.length === 0"
            :input-templ="{label: 'Plan end date'}"
            clearable
            @change="updateValue"
          />
        </validation-provider>
      </v-col>
      <v-col
        cols="12"
        lg="6"
        md="6"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="'Authorized shares'"
          :rules="{ required: true, min: 0 }"
        >
          <common-input-currency-input
            v-model="value.authorized_shares"
            label="Authorized shares"
            color="primary"
            :error-messages="errors"
            :hide-details="errors.length === 0"
            :options="{ precision: 0 }"
            @input="updateValue"
          />
        </validation-provider>
      </v-col>
      <v-col
        cols="12"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="'Return forfeited'"
        >
          <v-checkbox
            v-model="value.return_forfeited"
            label="Return forfeited options back to pool"
            color="primary"
            :error-messages="errors"
          />
        </validation-provider>
      </v-col>
    </v-row>
    <v-row class="ma-0">
      <v-col cols="12">
        <div class="trsText--text text-h4 font-weight-medium">
          Termination grace periods
        </div>
        <div class="trsText--text text-caption text--light-1 mt-2">
          You can add more than one grace period by selecting the type of grace period in the drop down below
        </div>
      </v-col>
      <v-col cols="6">
        <validation-provider
          v-slot="{ errors }"
          :name="'Grace Period'"
        >
          <common-input-select
            v-model="gracePeriod"
            label="Grace Period (optional)"
            placeholder="Select grace period"
            :items="gracePeriodList"
            item-text="title"
            item-value="title"
            :error-messages="errors"
            :hide-details="errors.length === 0"
            @change="addGracePeriod"
          />
        </validation-provider>
      </v-col>
    </v-row>
    <v-row
      v-for="(item, key) in selectedGracePeriod"
      :key="key"
      class="ma-0 mt-2 grace-period-row"
    >
      <v-col>
        {{ item.name }}
      </v-col>
      <v-col cols="4">
        <validation-provider
          v-slot="{ errors }"
          :name="'Duration'"
          :rules="{ required: true, min_value: 0 }"
        >
          <common-input-text-field
            v-model="item.duration"
            label="Duration"
            color="primary"
            type="number"
            :error-messages="errors"
            :hide-details="errors.length === 0"
            @input="updateGracePeriod"
          />
        </validation-provider>
      </v-col>
      <v-col>
        <validation-provider
          v-slot="{ errors }"
          :name="'Duration type'"
          :rules="{ required: true }"
        >
          <common-input-select
            v-model="item.duration_type"
            label="Duration Type"
            placeholder="Select duration type"
            :items="CONSTS.GRACE_PERIOD_DURATION_TYPE_LIST"
            item-text="title"
            item-value="value"
            :error-messages="errors"
            :hide-details="errors.length === 0"
            @change="updateGracePeriod"
          />
        </validation-provider>
      </v-col>
      <v-col cols="1">
        <v-icon
          color="primary"
          @click="deleteGracePeriod(key)"
        >
          mdi-delete
        </v-icon>
      </v-col>
    </v-row>
    <v-row class="mx-0">
     <v-col
     cols="12"
     >
        <notes-and-documents-form
          :id="eipId"
          :mode="mode"
          :is-submit="isSubmit"
          :internal-dialog="internalDialog"
          :type="CONSTS.NOTES_DOCUMENT_API_TYPE.EQUITY_INCENTIVE_PLANS"
          @navToList="$emit('navToList', true)"
        />
     </v-col>
    </v-row>
  </v-form>
</template>
<script>
  import moment from 'moment'
  import InputTemplateDate from '@/components/common/fmsvc/form-templates/InputTemplateDate'
  import NotesAndDocumentsForm from '@/components/common/captable/components/forms/NotesAndDocumentsForm.vue'

  export default {
    name: 'EipForm',

    components: {
      InputTemplateDate,
      NotesAndDocumentsForm,
    },

    props: {
      value: {
        type: Object,
        default: () => {},
      },
      shareTerms: {
        type: Array,
        required: true,
      },
      mode: {
        type: String,
        default: 'create',
      },
      eipId: {
        type: String,
      },
      isSubmit: {
        type: Boolean,
        default: false,
      },
      internalDialog: {
        type: Boolean,
        default: false,
      },
    },
    data: () => ({
      gracePeriod: '',
      selectedGracePeriod: [],
      holderApprovalDate: '',
      boardApprovalDate: '',
      planEndDate: '',
    }),

    computed: {
      gracePeriodList () {
        return this.getGracePeriodList()
      },
    },
    watch: {
      value: {
        handler (val) {
          if (val) {
            this.selectedGracePeriod = this.value.grace_periods ? this.value.grace_periods : []
            this.selectedGracePeriod.forEach((element) => {
              element.name = Object.keys(this.CONSTS.GRACE_PERIODS).find(key => this.CONSTS.GRACE_PERIODS[key] === element.type)
            })
            this.holderApprovalDate = val.shareholders_approved_on ? moment(val.shareholders_approved_on).format('MM/DD/YYYY') : ''
            this.boardApprovalDate = val.board_adopted_on ? moment(val.board_adopted_on).format('MM/DD/YYYY') : ''
            this.planEndDate = val.plan_ends_on ? moment(val.plan_ends_on).format('MM/DD/YYYY') : ''
          }
        },
      },
      holderApprovalDate: {
        handler (val) {
          if (val) {
            this.value.shareholders_approved_on = moment(val).format('YYYY-MM-DD')
          }
        },
        immediate: true,
      },
      boardApprovalDate: {
        handler (val) {
          if (val) {
            this.value.board_adopted_on = moment(val).format('YYYY-MM-DD')
          }
        },
        immediate: true,
      },
      planEndDate: {
        handler (val) {
          if (val) {
            this.value.plan_ends_on = moment(val).format('YYYY-MM-DD')
          }
        },
        immediate: true,
      },
    },

    methods: {
      updateValue () {
        this.$emit('update', this.value)
      },
      updateGracePeriod () {
        this.value.grace_periods = this.selectedGracePeriod
        this.updateValue()
      },
      getGracePeriodList () {
        const list = []
        Object.keys(this.CONSTS.GRACE_PERIODS).forEach((key) => {
          list.push({
            title: key,
            value: this.CONSTS.GRACE_PERIODS[key],
          })
        })
        return list
      },
      addGracePeriod () {
        this.selectedGracePeriod.push({
          name: this.gracePeriod.slice(),
          type: this.CONSTS.GRACE_PERIODS[this.gracePeriod],
          duration_type: '',
          duration: '',
        })
        setTimeout(() => {
          this.gracePeriod = null
        }, 100)
      },
      deleteGracePeriod (index) {
        this.selectedGracePeriod.splice(index, 1)
      },
    },
  }
</script>
<style lang="scss" scoped>
  .v-input--selection-controls {
    margin-top: 0 !important;
  }
  .grace-period-row {
    line-height: 55px;
    background: var(--v-accent-base);
    border-radius: 4px;
  }
</style>
